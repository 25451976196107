*, *:before, *:after {
  box-sizing: border-box;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #D1D1D1 #f4f4f4;
}

body {
  width: 100vw;
  min-height: 100vh;
  padding-top: 40px;
  margin: 0 auto;
  background-color: #F1F6F6;
  font-family:  "Helvetica Neue",-apple-system, BlinkMacSystemFont,"Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media all and (-ms-high-contrast: none)  {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}
@media all and (-ms-high-contrast: active) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

::-webkit-scrollbar {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  max-width: 14px;
  max-height: 14px;
}
::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  border-radius: 0.5rem;
  background-color: #D1D1D1;
}